import { useParams } from "react-router-dom";
import { Unity, useUnityContext } from "react-unity-webgl";

const BASE_PATH = "game-builds";
const LOADER_PATH = "Main.loader.js";
const DATA_PATH = "Main.data";
const FRAMEWORK_PATH = "Main.framework.js";
const CODE_PATH = "Main.wasm";

export function Game() {
  const { gameName } = useParams();
  const gamePath = `/${BASE_PATH}/${gameName}`;
  
  const { unityProvider, isLoaded, loadingProgression, } = useUnityContext({
    loaderUrl: `${gamePath}/${LOADER_PATH}`,
    dataUrl: `${gamePath}/${DATA_PATH}`,
    frameworkUrl: `${gamePath}/${FRAMEWORK_PATH}`,
    codeUrl: `${gamePath}/${CODE_PATH}`,
  });
  
  const loadingPercentage = Math.round(loadingProgression * 100);
 
  return (
    <>
      {isLoaded === false && (
        // We'll conditionally render the loading overlay if the Unity
        // Application is not loaded.
        <div className="flex items-center justify-center h-screen text-4xl">
          <p>Loading... ({loadingPercentage}%)</p>
        </div>
      )}
      <Unity unityProvider={unityProvider} className="h-screen w-screen" />
    </>
  )
}