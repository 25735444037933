import { useNavigate } from 'react-router-dom';

const images = [
  {  
    title: 'Lost Robot, Found Robot', 
    description: 'A robot that has lost all his parts and needs to find them.',
    url: 'lost-robot-found-robot',
  },
  { 
    title: 'Comedy Club Manager 3000', 
    description: 'Manage a comedy club and make it the best in the world.',
    url: 'comedy-club-manager-3000',
  },
  { 
    title: 'Space Carrier', 
    description: 'Transport goods between planets in your space carrier.',
    url: 'space-carrier',
  },
  {
    title: "Home For Everyone 3",
    description: "A game about building a home for everyone.",
    url: "home-for-everyone-3",
  },
  {
    title: "Kiki & Ikik",
    description: "A game about Kiki and Ikik.",
    url: "kiki-ikik",
  },
  {
    title: "Repair War",
    description: "Repair your machine faster than you're oponnent. Local multiplayer game.",
    url: "repair-war",
  },
];

type Image = typeof images[0]; 

const ImageGallery = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Game Jam Gallery
        </h1>
        <p className="header-subtitle">Explore the collection of games</p>
      </header>
      <div className="max-w-screen-lg mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {images.map((image, index) => (
            <GameCard image={image}  index={index}/>
          ))}
        </div>
      </div>
    </div>
  );
};

interface GameCardProps {
  image: Image;
  index: number;
}

function GameCard({image, index}: GameCardProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/game/${image.url}`);
  };

  const imageUrl = `/game-builds/${image.url}/game-icon.png`;
  return (
    <div key={index} className="bg-gray-100 shadow-lg rounded-lg overflow-hidden cursor-pointer" onClick={handleClick}>
      <img src={imageUrl} alt={image.title} className="w-full object-cover" style={{ height: '20rem' }} />
      <div className="p-4">
        <h2 className="text-xl font-bold mb-2 text-gray-800">{image.title}</h2>
        <p className="text-gray-700">{image.description}</p>
      </div>
    </div>
  )
}

export default ImageGallery;
