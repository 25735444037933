import './App.css';
import ImageGallery from './components/gallery';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { Game } from './games/game';

function App() {
  return <Routing />;
}

function Routing() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ImageGallery />} />
        <Route path="/game/:gameName" element={<Game />} />
      </Routes>
    </Router>
  );
}

export default App;
